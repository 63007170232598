import React, { PureComponent } from 'react'
import { CommentSection } from 'react-comments-section'
import 'react-comments-section/dist/index.css'

class Comments extends PureComponent {
  state = {
    data: [
      {
        userId: '03c',
        comId: '019',
        fullName: 'John Doe',
        avatarUrl: 'https://ui-avatars.com/api/name=John+Doe&background=random',
        userProfile: 'https://www.linkedin.com/in/johndoe/',
        text: 'This article is very insightful! Thanks for sharing! 📚',
        replies: [],
      },
      {
        userId: '04d',
        comId: '020',
        fullName: 'Jane Smith',
        avatarUrl:
          'https://ui-avatars.com/api/name=Jane+Smith&background=random',
        userProfile: 'https://www.linkedin.com/in/janesmith/',
        text: 'I have a question regarding the implementation details. 🤔',
        replies: [],
      },
    ],
  }

  onSubmitAction = (data) => {
    console.log('New comment posted:', data)
    // Optionally update state here to include the new comment
  }

  customNoComment = () => <div className="no-com">Be the first to comment!</div>

  render() {
    return (
      <div style={{ margin: '10px' }}>
        <h2 style={{ fontSize: '24px', marginBottom: '15px' }}>
          Community Feedback
        </h2>
        <CommentSection
          currentUser={{
            currentUserId: '01a',
            currentUserImg:
              'https://ui-avatars.com/api/name=Riya&background=random',
            currentUserProfile:
              'https://www.linkedin.com/in/riya-negi-8879631a9/',
            currentUserFullName: 'Riya Negi',
          }}
          commentData={this.state.data}
          onSubmitAction={(data) => this.onSubmitAction(data)}
          customNoComment={() => this.customNoComment()}
          logIn={{
            loginLink: 'http://localhost:3001/',
            signupLink: 'http://localhost:3001/',
          }}
          inputStyle={{
            border: '1px solid rgb(208 208 208)',
            padding: '5px',
            borderRadius: '5px',
            fontSize: '14px',
          }}
          submitBtnStyle={{
            border: '1px solid black',
            backgroundColor: '#007BFF',
            color: 'white',
            padding: '7px 12px',
            borderRadius: '5px',
          }}
          cancelBtnStyle={{
            border: '1px solid gray',
            backgroundColor: 'lightgray',
            color: 'black',
            padding: '7px 12px',
            borderRadius: '5px',
          }}
          formStyle={{
            backgroundColor: 'transparent',
            padding: '10px',
          }}
          customEditBtn={null} // Removes the edit button
        />
      </div>
    )
  }
}

export default Comments
