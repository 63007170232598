import * as React from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'

// ComboBox Component
export function ComboBox() {
  // Array of top 100 films
  const top100Films = [
    { title: 'The Shawshank Redemption', year: 1994 },
    { title: 'The Godfather', year: 1972 },
    { title: 'The Godfather: Part II', year: 1974 },
    { title: 'The Dark Knight', year: 2008 },
    { title: '12 Angry Men', year: 1957 },
    { title: "Schindler's List", year: 1993 },
    { title: 'The Lord of the Rings: The Return of the King', year: 2003 },
    { title: 'Pulp Fiction', year: 1994 },
    { title: 'The Good, the Bad and the Ugly', year: 1966 },
    { title: 'Fight Club', year: 1999 },
    // Add more movies as needed
  ]

  return (
    <Autocomplete
      disablePortal
      options={top100Films}
      getOptionLabel={(option) => option.title} // Display movie title in dropdown
      sx={{ width: 300, marginTop: 8 }} // Set width and top margin
      renderInput={(params) => <TextField {...params} label="Movie" />} // Render input field
    />
  )
}

export default ComboBox
