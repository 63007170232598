import React, { useEffect, useRef, useState } from 'react'
import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import gsap from 'gsap'
import CustomizedSteppers from './CustomizedSteppers' // Import your CustomizedSteppers component

function MyThree({ swipeCount }) {
  const refContainer = useRef(null)
  const modelRef = useRef(null) // Create a ref for the model
  const [scene, setScene] = useState(null) // State for the scene

  useEffect(() => {
    // Initialize the scene
    const newScene = new THREE.Scene()
    const camera = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      0.1,
      1000,
    )
    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true }) // Set alpha to true
    renderer.setSize(window.innerWidth, window.innerHeight)
    renderer.setPixelRatio(window.devicePixelRatio)

    if (refContainer.current) {
      refContainer.current.appendChild(renderer.domElement)
    }

    // Add light
    const light = new THREE.DirectionalLight(0xffffff, 1)
    light.position.set(5, 5, 5).normalize()
    newScene.add(light)

    // Animation mixer
    let mixer

    // Load GLTF model
    const loader = new GLTFLoader()
    loader.load(
      'https://raw.githubusercontent.com/nishkarsh1215/testFrontend/main/public/untitled_new_updated.glb',
      function (gltf) {
        const model = gltf.scene
        modelRef.current = model // Store the model in the ref
        // Optional: Adjust scale and position of the model
        model.scale.set(0.7, 0.7, 0.7) // Adjust scale if needed
        model.position.set(0, -2, 0) // Adjust position if needed
        newScene.add(model)

        // Initialize mixer and play all animations
        mixer = new THREE.AnimationMixer(model)
        gltf.animations.forEach((clip) => {
          mixer.clipAction(clip).play()
        })
      },
      undefined,
      function (error) {
        console.error('An error happened', error)
      },
    )

    camera.position.z = 5

    // Clock for animations
    const clock = new THREE.Clock()

    // Animation loop
    const animate = function () {
      requestAnimationFrame(animate)

      // Update mixer for animations
      if (mixer) {
        mixer.update(clock.getDelta())
      }

      renderer.render(newScene, camera)
    }
    animate()

    // Save the scene to state
    setScene(newScene)

    // Clean up on component unmount
    return () => {
      if (mixer) {
        mixer.stopAllAction()
      }
      refContainer.current?.removeChild(renderer.domElement)
    }
  }, [])

  function removeEntity() {
    // Check if scene and modelRef.current are defined
    if (scene && modelRef.current) {
      const selectedObject = scene.getObjectByName(modelRef.current.name)
      if (selectedObject) {
        console.log('Removing object:')
        scene.remove(selectedObject)
      }
    }
  }

  function moveTo(x, y, z) {
    if (modelRef.current) {
      gsap.to(modelRef.current.position, {
        x: x,
        y: y,
        z: z,
        duration: 1, // Animation duration in seconds
        ease: 'power1.inOut',
      })
    }
  }

  function scaleModel(scale) {
    if (modelRef.current) {
      gsap.to(modelRef.current.scale, {
        x: scale,
        y: scale,
        z: scale,
        duration: 1, // Animation duration in seconds
        ease: 'power1.inOut',
      })
    }
  }

  function rotateModel() {
    if (modelRef.current) {
      gsap.to(modelRef.current.rotation, {
        y: modelRef.current.rotation.z + Math.PI * 2,
        duration: 1, // Animation duration in seconds
        ease: 'power1.inOut',
      })
    }
  }

  function rotateModelX() {
    if (modelRef.current) {
      gsap.to(modelRef.current.rotation, {
        z: modelRef.current.rotation.z + Math.PI * 2,
        duration: 1, // Animation duration in seconds
        ease: 'power1.inOut',
      })
    }
  }

  function rotateModelAntiClockwise() {
    if (modelRef.current) {
      gsap.to(modelRef.current.rotation, {
        y: modelRef.current.rotation.z - Math.PI * 2,
        duration: 1, // Animation duration in seconds
        ease: 'power1.inOut',
      })
    }
  }

  useEffect(() => {
    if (swipeCount === 1) {
      moveTo(2, -2, 0)
    }
    if (swipeCount === 2) {
      rotateModel()
      moveTo(-2, -2, 0)
    }
    if (swipeCount === 3) {
      rotateModelAntiClockwise()
      moveTo(0, -2, 0)
    }
    if (swipeCount === 4) {
      rotateModel()
      moveTo(0, -2.2, 0)
      scaleModel(0.8)
    }
    if (swipeCount === 5) {
      rotateModelAntiClockwise()
      scaleModel(0.7)
    }
    if (swipeCount === 6) {
      moveTo(0, -2, 0)
      scaleModel(0.8)
    }
    if (swipeCount === 7) {
      rotateModel()
    }
    if (swipeCount === 8) {
    }
    if (swipeCount === 9) {
      rotateModelX()
      scaleModel(0.8)
    }
    if (swipeCount === 10) {
      moveTo(0, -2.2, 0)
      removeEntity()
    }
    if (swipeCount === 11) {
      removeEntity()
    }
    if (swipeCount === 12) {
      rotateModel()
    }
    if (swipeCount === 13) {
      removeEntity()
    }
  }, [swipeCount, scene])

  return (
    <div style={{ position: 'relative', width: '100%', height: '80vh' }}>
      <div
        ref={refContainer}
        style={{
          width: '100%',
          height: '100%',
          overflow: 'hidden',
          background: 'transparent', // Make background transparent
        }}
      ></div>
      {/* Add the CustomizedSteppers component below the 3D model */}
      <div
        style={{
          position: 'absolute',
          bottom: '0',
          left: '50%',
          transform: 'translate(-50%, -50%)', // Combine both X and Y translations
          zIndex: 1,
        }}
      >
        {/* <CustomizedSteppers /> */}
      </div>
    </div>
  )
}

export default MyThree
