// src/contexts/TokenContext.js

import React, { createContext, useContext, useState } from 'react'

// Create a context with default values
const TokenContext = createContext({
  tokens: 0,
  setTokens: () => {},
})

// Provider component
export const TokenProvider = ({ children }) => {
  const [tokens, setTokens] = useState(0)

  return (
    <TokenContext.Provider value={{ tokens, setTokens }}>
      {children}
    </TokenContext.Provider>
  )
}

// Custom hook to use the TokenContext
export const useTokens = () => useContext(TokenContext)
