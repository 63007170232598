import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import axios from 'axios'
import Avatar from '@mui/material/Avatar'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import ShareIcon from '@mui/icons-material/Share'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import FavoriteIcon from '@mui/icons-material/Favorite'
import Facebook from './Facebook'
import Comments from './Comments'
import './SaveBlog.css'

export function SaveBlog() {
  const { id } = useParams()
  const navigate = useNavigate()
  const [contentBeforeHeading, setContentBeforeHeading] = useState('')
  const [contentAfterHeading, setContentAfterHeading] = useState('')
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')
  const [userName, setUserName] = useState('')
  const [profileImage, setProfileImage] = useState('')
  const [publishDate, setPublishDate] = useState('Date not available')
  const [liked, setLiked] = useState(false)

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await axios.get(
          `https://sanctity-be-4yqw.onrender.com/getblog/${id}`,
        )
        if (response.data && response.data.content) {
          const {
            content,
            userName,
            profileImageUrl,
            createdAt,
          } = response.data
          setPublishDate(createdAt.split('T')[0])
          setUserName(userName || 'nishkarsh')
          setProfileImage(profileImageUrl || 'default-profile.png')

          const firstHeadingRegex = /(<h[1-6].*?>.*?<\/h[1-6]>)/i
          const parts = content.split(firstHeadingRegex)

          if (parts.length > 1) {
            setContentBeforeHeading(parts[0] + parts[1])
            setContentAfterHeading(parts.slice(2).join(''))
          } else {
            setContentBeforeHeading(content)
          }
        } else {
          navigate('/home')
        }
      } catch (error) {
        console.error('Error fetching blog:', error)
        setError('Failed to fetch blog content')
        navigate('/home')
      } finally {
        setLoading(false)
      }
    }

    fetchBlog()
  }, [id, navigate])

  const handleShare = () => {
    const shareUrl = window.location.href
    navigator.clipboard.writeText(shareUrl)
    alert('Blog URL copied to clipboard!')
  }

  const handleLike = () => {
    setLiked(!liked)
  }

  if (loading) {
    return <div className="loading-container">Loading...</div>
  }

  if (error) {
    return <div>{error}</div>
  }

  return (
    <div className="blog-content">
      <div dangerouslySetInnerHTML={{ __html: contentBeforeHeading }} />
      <hr />
      <div className="author-info">
        <Stack direction="row" spacing={2} alignItems="center">
          <Avatar
            alt={userName}
            src={profileImage}
            style={{ backgroundColor: '#3f51b5' }}
          />
          <p className="author-name">
            <strong>{userName}</strong>
          </p>
        </Stack>
        <p className="publish-date">{publishDate}</p>
        <div className="button-container">
          <Button
            variant="text"
            startIcon={<ShareIcon style={{ color: '#3f51b5' }} />}
            onClick={handleShare}
            className="sharebutton"
          />
          <Button
            variant="text"
            startIcon={
              liked ? (
                <FavoriteIcon style={{ color: '#f50057' }} />
              ) : (
                <FavoriteBorderIcon style={{ color: '#f50057' }} />
              )
            }
            onClick={handleLike}
            className="likebutton"
          />
        </div>
      </div>
      <hr />
      <div dangerouslySetInnerHTML={{ __html: contentAfterHeading }} />
      <Comments />
      <Facebook />
    </div>
  )
}

export default SaveBlog
