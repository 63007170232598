import React, { useState } from 'react'

export function Cloud() {
  const [imageUrl, setImageUrl] = useState(null)
  const [imageAlt, setImageAlt] = useState(null)

  const handleImageUpload = () => {
    const imageFile = document.querySelector('input[type="file"]')
    const files = imageFile.files

    if (!files.length) {
      console.log('No file selected.')
      return
    }

    console.log('Image file', files[0])

    const formData = new FormData()
    formData.append('file', files[0])
    // Replace this with your upload preset name
    formData.append('upload_preset', 'ml_default')
    const options = {
      method: 'POST',
      body: formData,
    }

    // Cloudinary cloud name
    return fetch(
      'https://api.cloudinary.com/v1_1/dmf5bbw6y/image/upload',
      options,
    )
      .then((res) => res.json())
      .then((res) => {
        setImageUrl(res.secure_url)
        setImageAlt(`An image of ${res.original_filename}`)
      })
      .catch((err) => console.log(err))
  }

  const openWidget = () => {
    // Create the widget
    const widget = window.cloudinary.createUploadWidget(
      {
        cloudName: 'dmf5bbw6y', // Cloudinary cloud name
        uploadPreset: 'ml_default', // Replace with your upload preset
      },
      (error, result) => {
        if (result.event === 'success') {
          setImageUrl(result.info.secure_url)
          setImageAlt(`An image of ${result.info.original_filename}`)
        }
      },
    )
    widget.open() // Open up the widget after creation
  }

  return (
    <div className="app">
      <section className="left-side">
        <form>
          <div className="form-group">
            <input type="file" />
          </div>

          <button type="button" className="btn" onClick={handleImageUpload}>
            Submit
          </button>
          <button type="button" className="btn widget-btn" onClick={openWidget}>
            Upload Via Widget
          </button>
        </form>
      </section>
      <section className="right-side">
        <p>The resulting image will be displayed here</p>
        {imageUrl && (
          <img src={imageUrl} alt={imageAlt} className="displayed-image" />
        )}
      </section>
    </div>
  )
}

export default Cloud
