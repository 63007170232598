import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom' // Import useNavigate and useParams
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Skeleton from '@mui/material/Skeleton'
import axios from 'axios'

function Media() {
  const [blogs, setBlogs] = useState([])
  const [loading, setLoading] = useState(true) // Initialize loading state
  const [error, setError] = useState()
  const navigate = useNavigate() // Initialize navigate
  const { id } = useParams()

  useEffect(() => {
    // Define the async function to fetch the blogs
    const fetchBlogs = async () => {
      try {
        const response = await axios.get(
          'https://sanctity-be-4yqw.onrender.com/getblogs',
          {
            withCredentials: true, // Ensures cookies (if any) are sent with the request
          },
        )
        const filteredBlogs = response.data.filter((item) => item.blogId !== id)
        setBlogs(filteredBlogs) // Store the filtered blogs in the state
        console.log(filteredBlogs) // Log the filtered blogs
      } catch (error) {
        console.error('Error fetching blogs:', error)
        setError('Failed to load blogs')
      } finally {
        setLoading(false) // Set loading to false after the fetch is done
      }
    }

    fetchBlogs() // Call the function when the component mounts
  }, [id]) // Add id to the dependency array

  const handleBlogClick = (blogId) => {
    console.log(blogId)
    window.location.href = `/saveblog/${blogId}`
  }

  return (
    <Box sx={{ mb: 4 }}>
      {' '}
      {/* Add margin-bottom here */}
      <Grid container spacing={2}>
        {loading
          ? Array.from(new Array(3)).map((
              _,
              index, // Create an array of 3 for skeletons
            ) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Box sx={{ margin: 'auto', textAlign: 'center' }}>
                  <Skeleton variant="rectangular" width="100%" height={150} />
                  {/* Skeleton height to match image height */}
                  <Box sx={{ pt: 0.5 }}>
                    <Skeleton />
                    <Skeleton width="60%" />
                  </Box>
                </Box>
              </Grid>
            ))
          : blogs.slice(0, 3).map((
              item, // Render only the first 3 filtered blogs
            ) => (
              <Grid item xs={12} sm={6} md={4} key={item.blogId}>
                <Box
                  sx={{
                    margin: 'auto',
                    textAlign: 'center',
                    cursor: 'pointer',
                  }} // Add cursor pointer
                  onClick={() => handleBlogClick(item.blogId)} // Handle blog click
                >
                  <img
                    style={{
                      width: '100%',
                      height: '150px', // Set a fixed height for uniformity
                      objectFit: 'cover', // Ensures the image covers the area without stretching
                    }}
                    alt={item.heading}
                    src={item.image}
                  />
                  <Box sx={{ pr: 2 }}>
                    <Typography gutterBottom variant="body2">
                      {item.heading}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            ))}
      </Grid>
    </Box>
  )
}

export default function Facebook() {
  return (
    <>
      <Typography variant="h6" sx={{ marginBottom: 2 }}>
        Similar Articles
      </Typography>
      <Box sx={{ overflow: 'hidden' }}>
        <Media />
      </Box>
    </>
  )
}
