import React, { useState } from 'react'
import './Stepper.css'

const Stepper = () => {
  const [activeStep, setActiveStep] = useState(0)
  const steps = ['Step 1', 'Step 2', 'Step 3']

  const handleNext = () => {
    if (activeStep < steps.length - 1) {
      setActiveStep(activeStep + 1)
    }
  }

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1)
    }
  }

  const handleReset = () => {
    setActiveStep(0)
  }

  return (
    <div className="stepper-container">
      <div className="stepper">
        {steps.map((step, index) => (
          <div
            key={index}
            className={`step ${index <= activeStep ? 'active' : ''}`}
          >
            {step}
          </div>
        ))}
      </div>
    </div>
  )
}

export default Stepper
