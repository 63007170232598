import React, { useState, useEffect, useRef } from 'react'
import './CategoryModal.css'

const CategoryModal = ({ isOpen, onClose, onSave, blogId }) => {
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [hashtags, setHashtags] = useState('')
  const [showConfirmation, setShowConfirmation] = useState(false)
  const modalRef = useRef(null) // Create a ref for the modal content

  const handleSelect = (category) => {
    setSelectedCategory(category)
  }

  const handleSave = () => {
    if (selectedCategory && hashtags) {
      onSave({ category: selectedCategory, hashtags }) // Pass both category and hashtags to save function
      setShowConfirmation(true) // Show confirmation message
    }
  }

  const handleHashtagsChange = (e) => {
    setHashtags(e.target.value) // Update hashtags state
  }

  const handleClickOutside = (event) => {
    // Close the modal if clicking outside the modal content
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onClose()
    }
  }

  useEffect(() => {
    // Add event listener for clicks
    document.addEventListener('mousedown', handleClickOutside)

    // Hide scrollbar on body when modal is open
    if (isOpen) {
      document.body.style.overflow = 'hidden' // Hide scrollbar
    } else {
      document.body.style.overflow = 'unset' // Restore scrollbar
    }

    // Clean up the event listener and restore body overflow on unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.body.style.overflow = 'unset' // Restore scrollbar on cleanup
    }
  }, [isOpen])

  if (!isOpen) return null

  return (
    <div className="modal-overlay">
      <div className="modal-content" ref={modalRef}>
        <h2 className="modal-heading">Select Blog Category</h2>
        <div className="category-buttons">
          {['AI-Ethics', 'AI-Community', 'AI-News'].map((category) => (
            <button
              key={category}
              onClick={() => handleSelect(category)}
              className={selectedCategory === category ? 'active' : ''}
            >
              {category}
            </button>
          ))}
        </div>
        <h3 className="modal-heading">Add Hashtags</h3>
        <input
          type="text"
          className="hashtag-input"
          value={hashtags}
          onChange={handleHashtagsChange}
          placeholder="Add hashtags, separated by commas"
        />
        <div className="popular-hashtags">
          {['#ainews', '#aiethics', '#aicommunity'].map((tag) => (
            <button
              key={tag}
              onClick={() =>
                setHashtags((prev) => (prev ? `${prev}, ${tag}` : tag))
              }
              className="hashtag-button"
            >
              {tag}
            </button>
          ))}
        </div>
        {/* Flex container for buttons */}
        <div className="button-container">
          <button className="save-button" onClick={handleSave}>
            Save
          </button>
        </div>
        {showConfirmation && (
          <div className="confirmation-message">
            Blog saved! You can view your blog at{' '}
            <a
              href={`https://sanctity.ai/saveblog/${blogId}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              sanctity.ai/saveblog/{blogId}
            </a>
          </div>
        )}
      </div>
    </div>
  )
}

export default CategoryModal
