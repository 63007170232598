import React, { useState, useEffect } from 'react'
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import './custom-quill.css'
import { useParams } from 'react-router-dom'
import { onAuthStateChanged } from 'firebase/auth'
import { auth } from './LoginFirebase'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import ImageResize from 'quill-image-resize-module-react'
import CategoryModal from './CategoryModal' // Import the CategoryModal component

// Register the image resize module
Quill.register('modules/imageResize', ImageResize)

const modules = {
  toolbar: {
    container: [
      [{ header: '1' }, { header: '2' }],
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ align: [] }],
      ['link', 'image'], // Image upload button
      ['clean'],
    ],
  },
  imageResize: {
    parchment: Quill.import('parchment'),
    modules: ['Resize', 'DisplaySize'], // Include resizing modules
  },
  clipboard: {
    matchVisual: false,
  },
}

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'list',
  'bullet',
  'align',
  'link',
  'image',
]

export function QuilBot() {
  const { id } = useParams()
  const [value, setValue] = useState('')
  const [userName, setUserName] = useState('')
  const [userEmail, setUserEmail] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [hashtags, setHashtags] = useState('') // State for hashtags
  const [isModalOpen, setIsModalOpen] = useState(false) // State to control modal visibility
  const [selectedCategory, setSelectedCategory] = useState(null) // State for selected category
  const navigate = useNavigate()

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUserName(currentUser.displayName || 'User')
        setUserEmail(currentUser.email || 'No email provided')
        if (
          currentUser.email !== 'nishkarsh1512@gmail.com' &&
          currentUser.email !== 'sanctity.ai.fs@gmail.com'
        ) {
          navigate('/')
        }
      } else {
        setUserName('')
        setUserEmail('')
      }
    })

    const fetchBlogData = async () => {
      try {
        const response = await axios.get(
          `https://sanctity-be-4yqw.onrender.com/getblog/${id}`,
        )
        const { content } = response.data
        setValue(content)
      } catch (error) {
        console.error('Error fetching blog content:', error)
      }
    }

    fetchBlogData()
    return () => unsubscribe()
  }, [id, navigate])

  const handleImageUpload = async (file) => {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('upload_preset', 'ml_default') // Replace with your upload preset

    try {
      const response = await fetch(
        'https://api.cloudinary.com/v1_1/dmf5bbw6y/image/upload',
        {
          method: 'POST',
          body: formData,
        },
      )

      const data = await response.json()
      const imageUrl = data.secure_url
      const blogImageTag = `<img src="${imageUrl}" alt="Image for blog ID ${id}" />`
      setValue((prevValue) => prevValue + blogImageTag) // Insert the image URL into the editor
    } catch (error) {
      console.error('Error uploading image:', error)
    }
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0]
    if (file) {
      handleImageUpload(file)
    }
  }

  const handleHashtagGeneration = () => {
    // Generate hashtags based on content (a simple example extracting keywords)
    const words = value
      .replace(/<[^>]*>/g, '') // Remove HTML tags
      .split(/\s+/)
      .filter((word) => word.length > 3) // Filter words longer than 3 characters
    const uniqueWords = [...new Set(words)]
    const generatedHashtags = uniqueWords
      .slice(0, 10)
      .map((word) => `#${word.toLowerCase()}`)
    setHashtags(generatedHashtags.join(' '))
  }

  const handleSave = async (category) => {
    const parser = new DOMParser()
    const doc = parser.parseFromString(value, 'text/html')

    const heading = doc.querySelector('h1, h2, h3, h4, h5, h6')
    const image = doc.querySelector('img')

    const today = new Date().toISOString().split('T')[0] // Format YYYY-MM-DD

    const data = {
      userName,
      userEmail,
      blogId: id,
      likes: 0,
      date: today, // Add today's date
      heading: heading ? heading.textContent : '',
      image: image ? image.src : '',
      content: value,
      hashtags, // Save hashtags
      category, // Save the selected category
    }

    try {
      await axios.post(
        `https://sanctity-be-4yqw.onrender.com/saveblog/${id}`,
        data,
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        },
      )
      setSuccessMessage('Blog content saved successfully!')
    } catch (error) {
      console.error('Error saving blog content:', error)
      setSuccessMessage('Failed to save blog content')
    }
  }

  const openModal = () => {
    setIsModalOpen(true) // Open modal when Save button is clicked
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  return (
    <div className="quilbot-container">
      <input type="file" accept="image/*" onChange={handleFileChange} />
      <ReactQuill
        theme="snow"
        value={value}
        onChange={setValue}
        modules={modules}
        formats={formats}
      />

      {successMessage && (
        <div
          style={{
            marginTop: '5px',
            padding: '10px',
            borderRadius: '4px',
            color: '#00796b',
            textAlign: 'left',
          }}
        >
          {successMessage}
        </div>
      )}
      <button
        onClick={openModal} // Open modal instead of directly saving
        style={{
          backgroundColor: 'black',
          color: 'white',
          border: 'none',
          borderRadius: '6px',
          padding: '8px 16px',
          fontSize: '14px',
          cursor: 'pointer',
          marginTop: '20px',
          display: 'block',
          width: '100%',
          maxWidth: '150px',
          textAlign: 'center',
          boxShadow: '0 3px 6px rgba(0,0,0,0.2)',
          transition: 'background-color 0.3s ease',
        }}
        onMouseOver={(e) => (e.currentTarget.style.backgroundColor = '#333')}
        onMouseOut={(e) => (e.currentTarget.style.backgroundColor = 'black')}
      >
        Save
      </button>

      {/* Render the modal */}
      <CategoryModal
        isOpen={isModalOpen}
        onClose={closeModal}
        onSave={handleSave}
        blogId={id}
      />
    </div>
  )
}

export default QuilBot
